import React, { useState, useEffect, useRef } from "react";
import { Rnd } from "react-rnd";
import "./DraggableWidget.css";
import esrimapStore from "./../../stores/esrimapStore"; // Import the store
import { observer } from "mobx-react-lite";

const DraggableWidget = observer(
  ({ initialPosition, onClose, content, onApiCall }) => {
    const [minimized, setMinimized] = useState(false);
    const [maximized, setMaximized] = useState(false);
    const [size, setSize] = useState({
      width: initialPosition.width,
      height: initialPosition.height,
    });
    const legendDivRef = useRef(null);

    const toggleMinimize = () => {
      setMinimized(!minimized);
    };

    const toggleMaximize = () => {
      setMaximized(!maximized);
      setSize(
        maximized
          ? { width: 150, height: 150 }
          : { width: window.innerWidth - 50, height: window.innerHeight - 50 }
      );
    };
    useEffect(() => {
      esrimapStore.legend["container"] = legendDivRef.current;
    });
    return (
      <Rnd
        default={{
          x: initialPosition.x,
          y: initialPosition.y,
          width: size.width,
          height: size.height,
        }}
        size={size}
        bounds="window"
        className={`draggable-widget ${minimized ? "minimized" : ""}`}
      >
        <div className="widget-header">
          <button className="minimize-btn" onClick={toggleMinimize}>
            {minimized ? "⬆" : "⬇"}
          </button>
          <button className="maximize-btn" onClick={toggleMaximize}>
            {maximized ? "🗗" : "🗖"}
          </button>
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        {!minimized && (
          <div className="widget-content">
            <div
              ref={legendDivRef}
              style={{ flex: 1, padding: "10px", borderLeft: "1px solid #ddd" }}
            ></div>
           
          </div>
        )}
         
      </Rnd>
    );
  }
);

export default DraggableWidget;
