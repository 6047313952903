// src/components/Header/Header.js

import React from 'react';
import './Header.css';
import headerImage from '../../assets/logo.jpg'; // Import your image

const Header = ({ onOpenWidget }) => {
  return (
    <header className="App-header">
      <div className="logo-container">
        <img src={headerImage} alt="Logo" className="logo" />
        <span className="header-title"></span>
      </div>
      {/* <div className="header-right">
        <button className="header-button" onClick={onOpenWidget}>
          Open Widget
        </button>
      </div> */}
    </header>
  );
};

export default Header;
