import React, { useState, useEffect } from 'react';
import './ResizableTable.css'; // Import the CSS for styling

const ResizableTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(40); // Default height
  const [isResizing, setIsResizing] = useState(false);

  // Toggle table open/close
  const toggleTable = () => {
    setIsOpen(!isOpen);
  };

  // Handle mouse down on drag handle
  const handleMouseDown = (e) => {
    setIsResizing(true);
  };

  // Handle mouse move for resizing the table
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isResizing) {
        const newHeight = window.innerHeight - e.clientY;
        setHeight(newHeight);
      }else{
        setHeight(400);
      }
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  return (
    <div
      className="table-container"
      style={{ height: isOpen ? `${height}px` : '20px' }}
    >
      <div className="drag-handle"  onClick={toggleTable} onMouseDown={handleMouseDown}>
     {isOpen ?<i class="fas fa-sort-down"></i>:<i class="fas fa-sort-up"></i>} 
      </div>
     
      {isOpen && (
        <div className="table-content">
       <table>
        <caption>Attribute Table of Sample GIS Data</caption>
        <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Feature Type</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>Central Park</td>
                <td>40.785091</td>
                <td>-73.968285</td>
                <td>Park</td>
            </tr>
            <tr>
                <td>2</td>
                <td>Times Square</td>
                <td>40.758896</td>
                <td>-73.985130</td>
                <td>Tourist Attraction</td>
            </tr>
            <tr>
                <td>3</td>
                <td>Brooklyn Bridge</td>
                <td>40.706086</td>
                <td>-73.996864</td>
                <td>Bridge</td>
            </tr>
            <tr>
                <td>4</td>
                <td>Statue of Liberty</td>
                <td>40.689247</td>
                <td>-74.044502</td>
                <td>Monument</td>
            </tr>
            <tr>
                <td>5</td>
                <td>Empire State Building</td>
                <td>40.748817</td>
                <td>-73.985428</td>
                <td>Skyscraper</td>
            </tr>
        </tbody>
    </table>
        </div>
      )}
    </div>
  );
};

export default ResizableTable;
