import { makeAutoObservable } from 'mobx';


class ESRImapStore {
  map = null;
  view = null;
  legend = null;

  constructor() {
    makeAutoObservable(this); // Automatically makes MobX observables and actions
  }

  setMap(map) {
    this.map = map;
  }

  setView(view) {
    this.view = view;
  }
}

const esrimapStore = new ESRImapStore();
export default esrimapStore;
