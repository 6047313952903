import React, { useEffect, useRef } from "react";
// import '@arcgis/core/assets/esri/themes/light/main.css';
// import WebMap from '@arcgis/core/WebMap';
// import MapView from '@arcgis/core/views/MapView';
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import Legend from "@arcgis/core/widgets/Legend";
import { observer } from 'mobx-react-lite';
import esrimapStore from "./../../stores/esrimapStore"; // Import the store

const EsriMap = observer(() => {
  const mapDiv = useRef(null);

  useEffect(() => {
    if (mapDiv.current) {
      const webmap = new WebMap({
        portalItem: {
          id: "06ad1d81aea24daeb020cba0d18fd9d4",
        },
      });
    //   mapStore;
      const view = new MapView({
        container: mapDiv.current,
        map: webmap,
      });
      esrimapStore.view = view
      const legend = new Legend({
        view: view,
        style: "classic", // other styles include 'classic'
      });
      
      esrimapStore.legend = legend
    //   view.ui.add(legend, "bottom-left");
      return () => view && view.destroy();
    }
  }, []);

  return (
    <div
      id="mapDiv"
      className="mapDiv"
      ref={mapDiv}
      style={{ height: "100%", width: "100%" }}
    ></div>
  );
});

export default EsriMap;
