// src/components/Sidebar/Sidebar.js

import React, { useState } from "react";
import "./MapButtons.css";

const MapButtons = () => {
  const onOpenWidget = () => {};
  return (
    <div class="button-grid">
      <button>Full Map</button>
      <button>Identify</button>
      <button>Zoning</button>
      <button>Aerial</button>
      <button>Overview Map</button>
    </div>
  );
};

export default MapButtons;
