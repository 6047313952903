// src/components/Sidebar/Sidebar.js

import React, { useState } from 'react';
import './Sidebar.css';

const Sidebar = ({ onOpenWidget , onToggleFooter}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <button className="toggle-button" onClick={toggleSidebar}>
        {/* {isCollapsed ? '<' : '>'} */}
        {isCollapsed ? <i class="fas fa-arrow-circle-left"></i> :<i class="fas fa-arrow-circle-right"></i>}

      </button>
      <ul className="sidebar-menu">
        <li onClick={onOpenWidget}>
          <i className="icon fa fa-search"></i>
          <span>Search Address</span>
        </li>
        
        <li onClick={() => onToggleFooter('Search Address Content')}>
          <i className="icon fab fa-buffer"></i>
          <span>Layers</span>
          </li>
        <li onClick={onOpenWidget}>
          <i className="icon fas fa-circle"></i>
          <span>Buffer</span>
          </li>
      
        <li onClick={onOpenWidget}>
          <i className="icon fas fa-file-export"></i>
          <span>Export</span>
          </li>
        
        <li onClick={onOpenWidget}>
          <i className="icon fa fa-draw-polygon"></i>
          <span>Graphics</span>
        </li>
        
        <li onClick={onOpenWidget}>
          <i className="icon fa fa-ruler-combined"></i>
          <span>Measure</span>
        </li>
       
        <li onClick={onOpenWidget}>
          <i className="icon fa fa-street-view"></i>
          <span>Street View</span>
        </li>
       
      </ul>
    </div>
  );
};

export default Sidebar;
