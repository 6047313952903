import React, { useState } from "react";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import DraggableWidget from "./components/DraggableWidget/DraggableWidget";
import Footer from "./components/Footer/Footer";
import "./App.css";
import EsriMap from "./components/maps/EsriMap";
import ResizableTable from "./components/Footer/ResizableTable"
import MapButtons from "./components/MapButtons/MapButtons"
function App() {
  const [widgets, setWidgets] = useState([]); // Array to store multiple widgets
  const [apiResponse, setApiResponse] = useState("");
  const [footerVisible, setFooterVisible] = useState(false);
  const [footerContent, setFooterContent] = useState("");
  const handleOpenWidget = () => {
    // Calculate the center position of the screen
    const centerX = window.innerWidth / 2 - 160; // Widget width is 320, so center it
    const centerY = window.innerHeight / 2 - 100; // Widget height is 200, so center it

    // Add a new widget with the calculated center position
    setWidgets((prevWidgets) => [
      ...prevWidgets,
      { id: prevWidgets.length, x: centerX, y: centerY },
    ]);
  };

  const handleCloseWidget = (id) => {
    // Remove the widget from the array
    setWidgets((prevWidgets) =>
      prevWidgets.filter((widget) => widget.id !== id)
    );
  };

  const handleApiCall = async () => {
    // Placeholder API call
    const response = await fetch(
      "https://jsonplaceholder.typicode.com/todos/1"
    );
    const data = await response.json();
    setApiResponse(JSON.stringify(data));
  };

  // Function to toggle the footer with dynamic content
  const toggleFooter = (content) => {
    setFooterContent(content); // Set the dynamic content in the footer
    setFooterVisible(true); // Show the footer
  };

  // Close the footer
  const closeFooter = () => {
    setFooterVisible(false);
  };
  return (
    <div className="App">
      <Header />
      <div className="mapContainer">
        <EsriMap />
      </div>
      <Sidebar onOpenWidget={handleOpenWidget} onToggleFooter={toggleFooter} />
      {/* <MainPanel apiResponse={apiResponse} /> */}
      {widgets.map((widget) => (
        <DraggableWidget
          key={widget.id}
          initialPosition={{
            x: widget.x,
            y: widget.y,
            width: 400,
            height: 300,
          }}
          onClose={() => handleCloseWidget(widget.id)}
          onApiCall={handleApiCall}
        />
      ))}
      {/* Footer outside MainPanel */}
      {/* <Footer
        isVisible={footerVisible}
        content={footerContent}
        onCloseFooter={closeFooter}
      /> */}
      {/* <ResizableTable/> */}
      <MapButtons/>
      <ResizableTable/>
    </div>
  );
}

export default App;
